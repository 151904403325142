import {memo} from "react"
import style from "styles/map.module.css"
import useLocale from '../../../../hooks/useLocale'

const Map = () => {
	const {mapSection} = useLocale()
	return (
		<div className={style.map}>
			<div className={style.container}>
				<div className={style.blockMainTexts}>
					<div className={style.blockAboutExplor} data-aos-once="true" data-aos="slide-up">
						<h2 className={style.titleAboutExplor}>
							{mapSection.title}
						</h2>
					</div>
					<div
						className={style.blockAbout}
						data-aos-once="true" data-aos="fade-right"
						data-aos-offset="100"
						data-aos-easing="ease-in-sine">
						<h3 className={style.titleHowManyMeters}>
							{mapSection.firstAdvantageTitle}
						</h3>
						<p className={style.titleAboutSquare}>
							{mapSection.firstAdvantageText}
						</p>
					</div>
					<div
						className={style.blockAbout}
						data-aos-once="true" data-aos="fade-right"
						data-aos-offset="100"
						data-aos-easing="ease-in-sine">
						<h3 className={style.titleHowManyMeters}>
							{mapSection.secondAdvantageTitle}
						</h3>
						<p className={style.titleAboutSquare}>
							{mapSection.secondAdvantageText}
						</p>
					</div>
					<div
						className={style.blockAbout}
						data-aos-once="true" data-aos="fade-right"
						data-aos-offset="100"
						data-aos-easing="ease-in-sine">
						<h3 className={style.titleHowManyMeters}>
							{mapSection.thirdAdvantageTitle}
						</h3>
						<p className={style.titleAboutHowManyPlay}>
							{mapSection.thirdAdvantageText}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default memo(Map)
